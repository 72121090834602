$("#burger-container").on('click', function (e) {
    e.stopPropagation();
    $(this).toggleClass("open");
    $('.menu-container li.has-submenu').removeClass("open");
    $('.menu-container').toggleClass("open");
});
$(".nav-item").on('click', function (e) {
    $('.menu-container').removeClass("open");
    $('#burger-container').removeClass("open");
});
if ($('div').hasClass("our-clients")) {
    window.addEventListener('scroll', function () {
        var clients = document.querySelector('#clients');
        var clientsPosition = clients.getBoundingClientRect();
        var spec = document.querySelector('#specialization');
        var specPosition = spec.getBoundingClientRect();
        var portfolio = document.querySelector('#portfolio');
        var portfolioPosition = portfolio.getBoundingClientRect();
        var contact = document.querySelector('#contact');
        var contactPosition = contact.getBoundingClientRect();

        if ((contactPosition.top - 500) <= 0) {
            $(".nav-item").removeClass("active");
            $(".contact-nav").addClass("active");
        } else if ((portfolioPosition.top - 200) <= 0) {
            $(".nav-item").removeClass("active");
            $(".portfolio-nav").addClass("active");
        } else if ((clientsPosition.top - 200) <= 0) {
            $(".nav-item").removeClass("active");
            $(".clients-nav").addClass("active");
        } else if ((specPosition.top - 200) <= 0) {
            $(".nav-item").removeClass("active");
            $(".spec-nav").addClass("active");
        }
    });
}
if ($('div').hasClass("our-clients")) {
    window.addEventListener('scroll', function () {
        var spec = document.querySelector('#specialization');
        var specPosition = spec.getBoundingClientRect();
        if ((specPosition.top - 300) <= 0) {
            $(".nav-logo").addClass("hidden");
        } else {
            $(".nav-logo").removeClass("hidden");
        }
    })
}
new WOW().init();